.App-logo {
  width: 150px;
  height: 150px;
  pointer-events: none;
}

.material-icons {
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.Toastify__toast.Toastify__toast--error {
  background: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
}

